import React, { Component } from 'react';
import { BsChevronLeft, BsChevronRight, BsGlobe } from "react-icons/bs";
import Ink from 'react-ink';
import { withRouter } from "react-router-dom";
import Axios from 'axios';
import DelayLink from 'react-delay-link';

export class BackButtonWithName extends Component {
    static contextTypes = {
        router: () => null
    };
    onHandleBack = () => {
        this.props.history.goBack()
    }
    changeLanguage = () => {
        if (localStorage.getItem("lang") == "en") {
            var lang = 'ar';
        } else {
            var lang = 'en';
        }

        Axios.post("https://cuts.howincloud.com/public/api/get-translations", {
            lang: lang,
        })
            .then(response => {
                if (response.data.lang) {
                    const langs = response.data.data;
                    localStorage.setItem('lang', lang)
                    langs.forEach(item => {
                        localStorage.setItem(item.key, item.value);
                    });
                }

                window.location.reload();
            });

    }
    render() {
        return (
            <React.Fragment>
                <div className='header-fixed'>
                    <div className='d-flex flex-row  align-items-center' style={{ backgroundColor: this.props.color ? this.props.color : '#fff' }}>
                        <div className='mb-2'>
                            <DelayLink to={"/home"}>
                                <div className='position-relative ms-3 me-3 mt-3 py-2'
                                    style={{ backgroundColor: '#fff', boxShadow: "rgb(0 0 0 / 15%) 0px 2px 20px", borderRadius: '22px/29px', paddingRight: "13px", paddingLeft: '13px' }}>
                                    {localStorage.getItem('lang') == 'ar' ?
                                        <BsChevronRight /> :
                                        <BsChevronLeft />
                                    }
                                    {/* <Ink style={{ color: '#aaaaaa' }} /> */}
                                </div>
                            </DelayLink>
                        </div>
                        <div className=' ms-3 mt-3 mb-2'>
                            <span className='fw-bold fs-6 user-select-none'>
                                {this.props.title}
                            </span>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withRouter(BackButtonWithName);