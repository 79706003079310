import "react-image-gallery/styles/css/image-gallery.css";

import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import Loadable from "react-loadable";
import Root from './Root';
import Loading from "./components/Mobile/helpers/Loading";
import FirstScreen from "./components/Mobile/FirstScreen";
import Onboard from "./components/Mobile/Onboard/index";
import SubCart from "./components/Mobile/Subscription/SubCart";
// const ScrollToTop = () => {
//   window.scrollTo(0, 0);
//   return null;
// };

const Profile = Loadable({
  loader: () => import("./components/Mobile/Profile"),
  loading: () => <Loading />,
});
// const SubCart = Loadable({
//   loader: () => import("./components/Mobile/Subscription/SubCart"),
//   loading: () => <Loading />,
// });
const Home = Loadable({
  loader: () => import("./components/Mobile/Home"),
  loading: () => <Loading />,
});
const Wallet = Loadable({
  loader: () => import("./components/Mobile/Wallet"),
  loading: () => <Loading />,
});

const SingleSubCategory = Loadable({
  loader: () => import("./components/Mobile/SingleSubCategory"),
  loading: () => <Loading />,
});
const GeoLocationPage = Loadable({
  loader: () => import("./components/Mobile/GeoLocationPage"),
  loading: () => <Loading />,
});
const Location = Loadable({
  loader: () => import("./components/Mobile/Location"),
  loading: () => <Loading />,
});
const SignUp = Loadable({
  loader: () => import("./components/Mobile/Login/SignUp"),
  loading: () => <Loading />,
});
const TrackMyOrder = Loadable({
  loader: () => import("./components/Mobile/TrackMyOrder"),
  loading: () => <Loading />,
});
const Plan = Loadable({
  loader: () => import("./components/Mobile/Plan"),
  loading: () => <Loading />,
});
const Success = Loadable({
  loader: () => import("./components/Mobile/Success"),
  loading: () => <Loading />,
});
const PaymentStatus = Loadable({
  loader: () => import("./components/Mobile/PaymentStatus"),
  loading: () => <Loading />,
});
const Login = Loadable({
  loader: () => import("./components/Mobile/Login"),
  loading: () => <Loading />,
});
const SelectedFeatures = Loadable({
  loader: () => import("./components/Mobile/Subscription/SingleSubscription/AddFeature/SelectedFeatures"),
  loading: () => <Loading />,
});
const SingleItem = Loadable({
  loader: () => import("./components/Mobile/SingleItem"),
  loading: () => <Loading />,
});
const Address = Loadable({
  loader: () => import("./components/Mobile/Address"),
  loading: () => <Loading />,
});
const MyOrder = Loadable({
  loader: () => import("./components/Mobile/MyOrder"),
  loading: () => <Loading />,
});
const AddFeatures = Loadable({
  loader: () => import("./components/Mobile/Subscription/SingleSubscription/AddFeature"),
  loading: () => <Loading />,
});
const ApplyVoucher = Loadable({
  loader: () => import("./components/Mobile/ApplyVoucher"),
  loading: () => <Loading />,
});
const SingleSubscription = Loadable({
  loader: () => import("./components/Mobile/Subscription/SingleSubscription"),
  loading: () => <Loading />,
});
const Subscription = Loadable({
  loader: () => import("./components/Mobile/Subscription"),
  loading: () => <Loading />,
});
const PaymentMethod = Loadable({
  loader: () => import("./components/Mobile/MyCart/PaymentMethod"),
  loading: () => <Loading />,
});
const MyCart = Loadable({
  loader: () => import("./components/Mobile/MyCart"),
  loading: () => <Loading />,
});
const ReferEarn = Loadable({
  loader: () => import("./components/Mobile/ReferEarn"),
  loading: () => <Loading />,
});
const Rates = Loadable({
  loader: () => import("./components/Mobile/Rating"),
  loading: () => <Loading />,
});
const Track = Loadable({
  loader: () => import("./components/Mobile/Track"),
  loading: () => <Loading />,
});
const Categories = Loadable({
  loader: () => import("./components/Mobile/Categories/index"),
  loading: () => <Loading />,
});
const BannerItems = Loadable({
  loader: () => import("./components/Mobile/BannerItems/index"),
  loading: () => <Loading />,
});

const SliderItems = Loadable({
  loader: () => import("./components/Mobile/SliderItems"),
  loading: () => <Loading />,
});
const SingleCategory = Loadable({
  loader: () => import("./components/Mobile/Home/MainCategories/SingleCategory"),
  loading: () => <Loading />,
});
const Story = Loadable({
  loader: () => import("./components/Mobile/Story"),
  loading: () => <Loading />,
});
const SubFloatCart = Loadable({
  loader: () => import("./components/Mobile/SubFloatCart"),
  loading: () => <Loading />,
});



ReactDOM.render(
  <Root>
    <BrowserRouter>
      <React.Fragment>
        {/* <Route component={ScrollToTop} /> */}
        <Switch>
          {/* <Route exact path={"/"} component={FirstScreen} /> */}
          <Route exact path={"/home"} component={withRouter(Home)} />
          <Route exact path={"/banner-items/:id"} component={withRouter(BannerItems)} />
          <Route exact path={"/slider-items/:id"} component={withRouter(SliderItems)} />
          <Route exact path={"/wallet"} component={withRouter(Wallet)} />
          <Route exact path={"/profile"} component={withRouter(Profile)} />
          <Route exact path={"/refer-earn"} component={withRouter(ReferEarn)} />
          <Route exact path={"/subscription"} component={withRouter(Subscription)} />
          <Route exact path={"/my-cart"} component={withRouter(MyCart)} />
          <Route exact path={"/rating/:orderId?"} component={withRouter(Rates)} />
          <Route exact path={"/track/:orderId?"} component={withRouter(Track)} />
          <Route exact path={"/single-subscription/:id"} component={withRouter(SingleSubscription)} />
          <Route exact path={"/apply-voucher"} component={withRouter(ApplyVoucher)} />
          <Route exact path={"/address"} component={withRouter(Address)} />
          <Route exact path={"/add-features/:id"} component={withRouter(AddFeatures)} />
          <Route exact path={"/my-order"} component={withRouter(MyOrder)} />
          <Route exact path={"/selected-features"} component={withRouter(SelectedFeatures)} />
          <Route exact path={"/single-items/:id"} component={withRouter(SingleItem)} />
          <Route exact path={"/payment-method"} component={withRouter(PaymentMethod)} />
          <Route exact path={"/success"} component={withRouter(Success)} />
          <Route exact path={"/PaymentStatus"} component={withRouter(PaymentStatus)} />
          <Route exact path={"/plan"} component={withRouter(Plan)} />
          <Route exact path={"/single-sub-category/:id"} component={withRouter(SingleSubCategory)} />
          <Route exact path={"/location"} component={withRouter(Location)} />
          <Route exact path={"/my-location"} component={withRouter(GeoLocationPage)} />
          <Route exact path={"/"} component={withRouter(Onboard)} />
          <Route exact path={"/track-my-order/:id"} component={withRouter(TrackMyOrder)} />
          <Route exact path={"/categories"} component={withRouter(Categories)} />
          <Route exact path={"/single-category/:id"} component={withRouter(SingleCategory)} />
          <Route exact path={"/login"} component={Login} />
          <Route exact path={"/register"} component={SignUp} />
          <Route exact path={"/story"} component={Story} />
          <Route exact path={"/sub-cart"} component={SubCart} />
          <Route exact path={"/sub-float-cart"} component={SubFloatCart}/>
        </Switch>
      </React.Fragment>
    </BrowserRouter>
  </Root>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
