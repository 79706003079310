/* eslint-disable import/no-anonymous-default-export */


import { GET_HOME_BANNERS, GET_HOME_SLIDERS,VIEW_STORIES ,UPDATE_STORIES_VIEW,GET_SEARCHITEM} from './actionTypes';


const initialState = {
    banners: [],
    sliders: [],
    stories: [],
    story_update: [],
    searches:[],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_HOME_BANNERS:
            return { ...state, banners: action.payload };
        case GET_HOME_SLIDERS:
            return { ...state, sliders: action.payload };
        case VIEW_STORIES:
            return { ...state, stories:  action.payload };
        case UPDATE_STORIES_VIEW:
            return { ...state, story_update:  action.payload };
         case GET_SEARCHITEM:
            return { ...state, searches:  action.payload };
        default:
            return state;
    }
}