/* eslint-disable import/no-anonymous-default-export */

import { DELETE_ADDRESS, GET_USER_ADDRESS, SAVE_ADDRESS, SET_DEFAULT } from "./actionTypes";


const initialState = {
    address: [],
    deleted_address:[],

};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER_ADDRESS:
            return { ...state, address: action.payload };
        case SAVE_ADDRESS:
            return { ...state, addresses: action.payload };
        case DELETE_ADDRESS:
            let {address}=action.payload
            return { ...state, deleted_address:address };
        case SET_DEFAULT:
            return { ...state, addresses: action.payload };
        default:
            return state;
    }
}