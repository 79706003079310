/* eslint-disable import/no-anonymous-default-export */

import {
    GET_ALL_CATEGORIES,
    GET_ALL_MAIN_CATEGORIES,
    GET_ALL_SINGLE_SUB_CATEGORIES,
    GET_ALL_SINGLE_SUB_ITEMS,
    GET_ALL_SINGLE_CATEGORIES
} from "./actionTypes";


const initialState = {
    main_categories: [],
    categories: [],
    single_categoires: [],
    sub_categories: [],
    sub_items: [],

};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_MAIN_CATEGORIES:
            return { ...state, main_categories: action.payload };
        case GET_ALL_CATEGORIES:
            return { ...state, categories: action.payload };
            case GET_ALL_SINGLE_CATEGORIES:
                return { ...state, single_categoires: action.payload };
        case GET_ALL_SINGLE_SUB_CATEGORIES:
            return { ...state, sub_categories: action.payload };
        case GET_ALL_SINGLE_SUB_ITEMS:
            return { ...state, sub_items: action.payload };
        default:
            return state;
    }
}