import { combineReducers } from "redux";
import userReducer from '../redux/user/reducer'
import categoriesReducer from '../redux/categories/reducer'
import ordersReducer from '../redux/myorder/reducer';
import addressReducer from '../redux/address/reducer';
import itemReducer from '../redux/items/reducer';
import cartReducer from '../redux/cart/reducer';
import homeReducer from '../redux/Home/reducer';
import subCartReducer from  '../redux/subCart/reducer';
import promotionReducer from '../redux/Promotions/reducer';


export default combineReducers({
    user: userReducer,
    categories: categoriesReducer,
    orders: ordersReducer,
    address: addressReducer,
    item: itemReducer,
    cart: cartReducer,
    home: homeReducer,
    subCart:subCartReducer,
    promotions: promotionReducer,
});