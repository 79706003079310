import React, { Component } from 'react';
// import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Spring, animated } from 'react-spring';
import  BackButtonWithName  from '../../../../components/Elements/BackButtonWithName';
import ContentLoader from 'react-content-loader';
import DelayLink from 'react-delay-link';
import { withRouter } from 'react-router-dom';
import { RiCloseFill } from "react-icons/ri";
import { AiOutlinePlusCircle,AiOutlineMinusCircle } from "react-icons/ai";
import { BsFillPlusCircleFill } from "react-icons/bs";

class SubCart extends Component {
    render() {
        console.log(this.props.cartProducts)
        return (
            <React.Fragment>
            <div style={{height:'100vh',backgroundColor:"#f8f6f6"}}>
            <div style={{width:'100%',paddingTop:"30px"}}>
                <h3 className='text-center' style={{margin:'0 auto'}}>Your Items  is</h3>
            </div>
            {this.props.cartProducts && 
            this.props.cartProducts.map((value,index)=>
            <div className='d-flex p-3 justify-content-between align-items-base' style={{backgroundColor:'#f8f6f6'}}>
                <div>
                   <div className='d-flex'>
                   <div className='rounded p-2' style={{width:"120px",backgroundColor:"#ffff"}}>
                  <img src={"https://cuts.howincloud.com/"+value.image1} style={{objectFit:"cover",width:'100%'}} />
                  </div>
                <div className='d-flex flex-column justify-content-center'>
                  <span>{value.name}</span>
                  
                  <span>SR:{value.selling_price}</span>
                </div>
                   </div>
                </div>
                
                <div className='d-flex flex-column justify-content-between align-items-end'>
                    <span> <RiCloseFill style = {{ fontSize: '1.5rem' } }/> </span>
                    <div className='d-flex' style={{alignItems:'center'}}>
                        <div>
                        <button className='btn'><AiOutlineMinusCircle style = {{ fontSize: '1.5rem' } }/></button> 
                        </div>
                        <div className=''><span >{value.quantity}</span></div>
                        <div ><button className='btn pe-0'><AiOutlinePlusCircle style = {{ fontSize: '1.5rem' } }/></button></div>
                    </div>
                </div>
            </div>
            
            )
            }
            </div>
            </React.Fragment>

        )
    }
}

// const mapStateToProps = (state) => ();


const mapStateToProps = (state) => ({
    singlePlan: state.user.singlePlan.data,
    cartProducts:state.subCart.subProducts, 
});

export default withRouter(connect(mapStateToProps, {
})(SubCart));