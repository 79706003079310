import {
  LOAD_CART,
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  UPDATE_CART,
  CALCULATE_BRANCH_MAX_DISTANCE,
  GET_BRANCH_INFO,
  APPLY_VOUCHER,
  DELIVERY_CHARGE,
  CALCULATE_TAX,
  VIEW_TIMESLOTS,
  VIEW_ALL_TIMESLOTS,
  SAVE_TIMESLOTS,
  PLACE_ORDER,
  CALCULATE_SURGE,
  ADD_SUB,
  ADD_MAIN_ITEM,
  LOAD_MAIN_ITEM,
  EMPTY_CART,
} from "./actionTypes";

const initialState = {
  products: [],
  maxDistance: [],
  branchInfo: [],
  total: [],
  discount: [],
  delivery_charges: "",
  tax: [],
  timeSlots: [],
  allTimeSlots: [],
  selectedTimeSlot: "",
  newOrder: [],
  surge_fee: "",
  subscription: [],
  main_items: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case LOAD_CART:
      return {
        ...state,
        products: action.payload,
      };
    case ADD_PRODUCT:
      return {
        ...state,
        productToAdd: Object.assign({}, action.payload),
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        productToRemove: Object.assign({}, action.payload),
      };
    case UPDATE_CART:
      return {
        ...state,
        total: action.payload,
      };
    case CALCULATE_BRANCH_MAX_DISTANCE:
      return {
        ...state,
        maxDistance: action.payload,
      };
    case GET_BRANCH_INFO:
      return {
        ...state,
        branchInfo: action.payload,
      };
    case APPLY_VOUCHER:
      return {
        ...state,
        discount: action.payload,
      };
    case DELIVERY_CHARGE:
      return {
        ...state,
        delivery_charges: action.payload,
      };
    case CALCULATE_TAX:
      return {
        ...state,
        tax: action.payload,
      };
    case VIEW_TIMESLOTS:
      return {
        ...state,
        timeSlots: action.payload,
      };
    case VIEW_ALL_TIMESLOTS:
      return {
        ...state,
        allTimeSlots: action.payload,
      };
    case SAVE_TIMESLOTS:
      console.log("from reduser", action.payload);
      return {
        ...state,
        selectedTimeSlot: Object.assign({}, action.payload),
      };
    case PLACE_ORDER:
      return {
        ...state,
        newOrder: action.payload,
      };
    case CALCULATE_SURGE:
      return {
        ...state,
        surge_fee: action.payload,
      };
    case ADD_SUB:
      return {
        ...state,
        subscription: action.payload,
      };
    case ADD_MAIN_ITEM:
      return {
        ...state,
        main_items: action.payload,
      };
    case LOAD_MAIN_ITEM:
      return {
        ...state,
        main_items: action.payload,
      };
    case EMPTY_CART:
      return {
        ...state,
        products: action.payload,
      };
    default:
      return state;
  }
}
