/* eslint-disable import/no-anonymous-default-export */
import {
    GET_SINGLE_BANNER_ITEM, GET_SINGLE_SLIDER_ITEM,
} from "./actionTypes";

const initialState = {
    banner: [],
    slider: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SINGLE_BANNER_ITEM:
            return { ...state, banner: action.payload };
        case GET_SINGLE_SLIDER_ITEM:
            return { ...state, slider: action.payload };
        default:
            return state;
    }
}