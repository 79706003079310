import { Provider } from "react-redux";
import React from "react";
import store from "./redux/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-spring-bottom-sheet/dist/style.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import '../src/assets/css/custom.css';
import '../src/assets/css/main.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
// const polling = {
// 	enabled: false,
// };



const Root = ({ children, initialState = {} }) => (
	<React.Fragment>
		<Provider store={store(initialState)}>
			{children}

		</Provider>
	</React.Fragment>
);


export default Root;
