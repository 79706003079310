import React, { Component } from 'react'
import animationData from '../../../assets/animations/loader.json';
import Lottie from 'react-lottie';
export class Loading extends Component {
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
            }
        };
        return (
            <React.Fragment>
                <div className="height-100 d-flex align-items-center overlay-loading ongoing-payment-spin">
                    <Lottie
                        options={defaultOptions}
                        height={350}
                        width={350}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default (Loading);