/* eslint-disable import/no-anonymous-default-export */

import {
    GET_ALL_ITEMS_POPULAR,
    GET_SINGLE_ITEM,
    GET_ALL_POPULAR_ITEMS,
    GET_SEARCH_SINGLE_ITEMS
} from "./actionTypes";


const initialState = {
    item: [],
    allItems: [],
    popularItems: [],
    SearchSingleItems: [],
    id:[]
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SINGLE_ITEM:
            return { ...state, item: action.payload };
        case GET_ALL_ITEMS_POPULAR:
            return { ...state, allItems: action.payload };
        case GET_ALL_POPULAR_ITEMS:
            return { ...state, popularItems: action.payload };
            case GET_SEARCH_SINGLE_ITEMS:
            return { ...state, id: action.payload };
        default:
            return state;
    }
}