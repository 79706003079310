import ProgressBar from '@ramonak/react-progress-bar';
import React, { Component } from 'react';
import DelayLink from 'react-delay-link';
import Ink from 'react-ink';
import { Spring, animated } from 'react-spring';
import { connect } from 'react-redux';
import Axios from 'axios';
import enTranslations from '../../helpers/lang/en.json';
import arTranslations from '../../helpers/lang/ar.json';
class Onboard extends Component {
    state = {
        first: true,
        second: false,
        third: false,
        getStarted: false,
    }

    componentDidMount() {
        if (!localStorage.getItem("lang")) {
            const lang = localStorage.getItem("lang") || 'en';
            this.setState({ selectedLang: lang });

            // Load translations based on the selected language
            const translations = lang === 'ar' ? arTranslations : enTranslations;
    
            // Store each translation item in localStorage
            localStorage.setItem('lang', lang); // Save the selected language
            Object.entries(translations).forEach(([key, value]) => {
                localStorage.setItem(key, value);
            });
    
            Axios.post("https://cuts.howincloud.com/public/api/get-translations", {
                lang: "ar",
            })
                .then(response => {
                    console.log(response);

                    if (response.data.lang) {
                        const langs = response.data.data;

                        console.log('====================================');
                        console.log('====================================');
                        localStorage.setItem('lang', 'ar');
                        langs.forEach(item => {
                            localStorage.setItem(item.key, item.value);
                        });
                    }

                    window.location.reload();
                });
        } else {
            if (localStorage.getItem('lang') == 'ar') {
                var parg = document.getElementById("changeDir");
                parg.dir = "rtl";
            } else {
                var parg = document.getElementById("changeDir");
                parg.dir = "ltr";
            }
            this.setState({ loading: false });
        }
        setTimeout(() =>
            this.setState({ second: true, first: false }),
            3000)
        setTimeout(() =>
            this.setState({ third: true, second: false, first: false }),
            6000);
        setTimeout(() =>
            this.setState({ getStarted: true, third: false, second: false, first: false }),
            9000);

    }
    __secondSlider = () => {
        this.setState({ second: true, first: false });
    }
    __thirdSlider = () => {
        this.setState({ third: true, first: false, second: false });
    }
    __getStarted = () => {
        this.setState({ getStarted: true, first: false, second: false, third: false });
    }
    render() {
        const { user } = this.props;
        if (user && user.success) {
            // console.log(user);
            window.location.href = '/home';
        }
        // if (user.success)
        return (
            <React.Fragment>
                {this.state.first &&
                    <Spring
                        from={{ opacity: 0, marginTop: '500px' }}
                        to={{ opacity: 1, marginTop: '0px' }}
                    // config={{ duration: 1000 }}
                    >
                        {styles => (
                            <animated.div style={styles}>
                                <React.Fragment>
                                    <div className='first-bg overflow-hidden' onClick={() => this.__secondSlider()} >
                                        <div className='pt-3 d-flex  align-items-around align-content-start flex-nowrap justify-content-around'>
                                            <div>
                                                <ProgressBar
                                                    completed={100}
                                                    bgColor="#45BC1B"
                                                    borderRadius="0px"
                                                    height="0.7vw"
                                                    width="20vw"
                                                    isLabelVisible={false}
                                                    baseBgColor="#c7c7c7"
                                                    maxCompleted={100}
                                                />
                                            </div>
                                            <div>

                                                <ProgressBar
                                                    completed={0}
                                                    bgColor="#45BC1B"
                                                    height="0.7vw"
                                                    width="20vw"
                                                    borderRadius="0px"
                                                    isLabelVisible={false}
                                                    baseBgColor="#c7c7c7"
                                                    maxCompleted={100}
                                                />
                                            </div>
                                            <div>
                                                <ProgressBar
                                                    completed={0}
                                                    bgColor="#45BC1B"
                                                    height="0.7vw"
                                                    width="20vw"
                                                    borderRadius="0px"
                                                    isLabelVisible={false}
                                                    baseBgColor="#c7c7c7"
                                                    maxCompleted={100}
                                                />
                                            </div>
                                            <div>
                                                <ProgressBar
                                                    completed={0}
                                                    bgColor="#45BC1B"
                                                    height="0.7vw"
                                                    width="20vw"
                                                    borderRadius="0px"
                                                    isLabelVisible={false}
                                                    baseBgColor="#c7c7c7"
                                                    maxCompleted={100}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column justify-content-start h-100 align-items-start px-2 mt-5' >
                                            <span className='fw-bold fs-4'>
                                                {localStorage.getItem('brow_the_largest_variety')}
                                            </span>
                                            {/* {localStorage.getItem("lang") === 'en' &&
                                                <React.Fragment>
                                                    <span className='fw-bold fs-4'>
                                                        {localStorage.getItem('of_fruit_vegetables')}
                                                    </span>
                                                    <span className='fw-bold fs-4'>
                                                        {localStorage.getItem('sweets_drink')}
                                                    </span>
                                                    <span className='fw-bold fs-4'>
                                                        {localStorage.getItem('more')}
                                                    </span>
                                                </React.Fragment>
                                            } */}
                                        </div>
                                    </div>

                                </React.Fragment>
                            </animated.div>
                        )
                        }
                    </Spring>
                }
                {this.state.second &&

                    <React.Fragment>
                        <div className='second-bg' onClick={this.__thirdSlider} >
                            <div className='pt-3 d-flex  align-items-around align-content-center flex-nowrap justify-content-around'>
                                <div>
                                    <ProgressBar
                                        completed={100}
                                        bgColor="#45BC1B"
                                        borderRadius="0px"
                                        height="0.7vw"
                                        width="20vw"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                                <div>

                                    <ProgressBar
                                        completed={100}
                                        bgColor="#45BC1B"
                                        height="0.7vw"
                                        width="20vw"
                                        borderRadius="0px"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                                <div>

                                    <ProgressBar
                                        completed={0}
                                        bgColor="#45BC1B"
                                        height="0.7vw"
                                        width="20vw"
                                        borderRadius="0px"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                                <div>
                                    <ProgressBar
                                        completed={0}
                                        bgColor="#45BC1B"
                                        height="0.7vw"
                                        width="20vw"
                                        borderRadius="0px"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                                {/* <DelayLink delay={200} to={"/login"}>
                                    <div className='mt-5 position-relative b-r-10'>
                                        <button className='btn btn-warning text-white b-r-10 fw-bold py-3 px-5' style={{ backgroundColor: '#FBA808', border: 'none', outline: 'none' }}>{localStorage.getItem('get_started')}</button>
                                        <Ink duration={500} style={{ color: '#aaaaaaa' }} />
                                    </div>
                                </DelayLink> */}
                            </div>
                            <div className='d-flex flex-column justify-content-start h-100 align-items-start px-2 mt-5' >
                                <span className='fw-bold fs-4'>
                                    {localStorage.getItem('track_your_delivery_in')}
                                </span>
                                {/* <span className='fw-bold fs-4'>
                                    {localStorage.getItem('realtime')}
                                </span> */}

                            </div>
                        </div>

                    </React.Fragment>

                }
                {this.state.third &&

                    <React.Fragment>
                        <div className='third-bg ' onClick={this.__getStarted}  >
                            <div className='pt-3 d-flex  align-items-around align-content-center flex-nowrap justify-content-around'>
                                <div>
                                    <ProgressBar
                                        completed={100}
                                        bgColor="#45BC1B"
                                        borderRadius="0px"
                                        height="0.7vw"
                                        width="20vw"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                                <div>

                                    <ProgressBar
                                        completed={100}
                                        bgColor="#45BC1B"
                                        height="0.7vw"
                                        width="20vw"
                                        borderRadius="0px"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                                <div>

                                    <ProgressBar
                                        completed={100}
                                        bgColor="#45BC1B"
                                        height="0.7vw"
                                        width="20vw"
                                        borderRadius="0px"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                                <div>
                                    <ProgressBar
                                        completed={0}
                                        bgColor="#45BC1B"
                                        height="0.7vw"
                                        width="20vw"
                                        borderRadius="0px"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-column justify-content-start h-100 align-items-start px-2 mt-5' >
                                <span className='fw-bold fs-4'>
                                    {localStorage.getItem('pickup_delivery')}
                                </span>
                                {/* <span className='fw-bold fs-4'>
                                    {localStorage.getItem('at_door_step_and_enjoy')}
                                </span>
                                <span className='fw-bold fs-4'>
                                    {localStorage.getItem('your_vegitables')}
                                </span>
                                <span className='fw-bold fs-4'>
                                    {localStorage.getItem('groceries')}
                                </span> */}
                            </div>
                        </div>

                    </React.Fragment>

                }
                {this.state.getStarted &&

                    <React.Fragment>
                        <div className='get-started-bg overflow-hidden' onClick={() => this.__getStarted()}  >
                            <div className='pt-3 d-flex  align-items-around align-content-center flex-nowrap justify-content-around '>
                                <div>
                                    <ProgressBar
                                        completed={100}
                                        bgColor="#1B6600"
                                        borderRadius="0px"
                                        height="0.7vw"
                                        width="20vw"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                                <div>

                                    <ProgressBar
                                        completed={100}
                                        bgColor="#1B6600"
                                        height="0.7vw"
                                        width="20vw"
                                        borderRadius="0px"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                                <div>

                                    <ProgressBar
                                        completed={100}
                                        bgColor="#1B6600"
                                        height="0.7vw"
                                        width="20vw"
                                        borderRadius="0px"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                                <div>
                                    <ProgressBar
                                        completed={100}
                                        bgColor="#1B6600"
                                        height="0.7vw"
                                        width="20vw"
                                        borderRadius="0px"
                                        isLabelVisible={false}
                                        baseBgColor="#c7c7c7"
                                        maxCompleted={100}
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-start align-content-around h-100  px-4 '
                                style={{ color: '#ffffff' }}>
                                <span className='fs-4 fw-normal '>
                                    {localStorage.getItem('cuts_satisfies_your_food')}
                                </span>
                                 {/* <span className='fs-4 fw-normal '>
                                    {localStorage.getItem('cravings_with_your_favourite')}
                                </span> <span className='fs-4 fw-normal '>
                                    {localStorage.getItem('food_delivered_to_you')}
                                </span>
                                <span className='fs-4 fw-normal '>
                                    {localStorage.getItem('wherever_you_are')}
                                </span> */}
                                <DelayLink delay={200} to={"/login"}>
                                    <div className='mt-3 position-relative b-r-10'>
                                        <button className='btn btn-warning text-white b-r-10 fw-bold py-3 px-5' style={{ backgroundColor: '#FBA808', border: 'none', outline: 'none' }}>
                                            {localStorage.getItem('get_started')}
                                        </button>
                                        <Ink duration={500} style={{ color: '#aaaaaaa' }} />
                                    </div>
                                </DelayLink>
                            </div>
                        </div>



                    </React.Fragment>
                }
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
})


export default (connect(mapStateToProps, {

}))(Onboard)
// export default (Home);