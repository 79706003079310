/* eslint-disable import/no-anonymous-default-export */
import {
    SEND_CUSTOM_OTP,
    REGISTER_USER_OTP,
    LOGIN_OTP_USER,
    LOGOUT_USER,
    UPDATE_USER_INFO,
    USER_UPDATE_USER_INFO,
    USER_WALLET_TRANSACTION,
    USER_VIEW_SUBSCRIPTION,
    USER_VIEW_SINGLE_SUBSCRIPTION,
    GET_PLAN_FEATURES,
    VIEW_PROFILE_DATA,
    RATE_OUR_ITEM,
    REFERAL_DETAILS,
    LIST_USER_VOUCHER
} from "./actionTypes";

const initialState = {
    user: [],
    subscription: [],
    singlePlan: [],
    reviews:[],
    wallet_transactions: [],
    features: [],
    refer_details:{},
    vouchers: [],
    

};

export default function (state = initialState, action) {
    switch (action.type) {
        case SEND_CUSTOM_OTP:
            return { ...state, user: action.payload };
        case LOGIN_OTP_USER:
            return { ...state, user: action.payload };
        case LOGOUT_USER:
            return { ...state, user: action.payload };
        case REGISTER_USER_OTP:
            return { ...state, user: action.payload };
        case UPDATE_USER_INFO:
            return { ...state, user: action.payload };
        case USER_UPDATE_USER_INFO:
            return { ...state, user: action.payload };
        case USER_WALLET_TRANSACTION:
            return { ...state, wallet_transactions: action.payload };
        case USER_VIEW_SUBSCRIPTION:
            return { ...state, subscription: action.payload };
        case USER_VIEW_SINGLE_SUBSCRIPTION:
            return { ...state, singlePlan: action.payload };
        case GET_PLAN_FEATURES:
            return { ...state, features: action.payload };
        case VIEW_PROFILE_DATA:
            return { ...state, user: action.payload };
        case RATE_OUR_ITEM:
            return { ...state,reviews:[]}  ;
        case REFERAL_DETAILS:
            return {...state,refer_details:action.payload}     ; 
        case LIST_USER_VOUCHER:
            return {...state,vouchers:action.payload}     ; 
        default:
            return state;
    }
}