export const LOAD_CART = "LOAD_CART";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const UPDATE_CART = "UPDATE_CART";
export const CALCULATE_BRANCH_MAX_DISTANCE = "CALCULATE_BRANCH_MAX_DISTANCE";
export const GET_BRANCH_INFO = "GET_BRANCH_INFO";
export const FORCE_RELOAD = "FORCE_RELOAD";
export const APPLY_VOUCHER = "APPLY_VOUCHER";
export const DELIVERY_CHARGE = "DELIVERY_CHARGE";
export const CALCULATE_TAX = "CALCULATE_TAX";
export const VIEW_TIMESLOTS = "VIEW_TIMESLOTS";
export const VIEW_ALL_TIMESLOTS = "VIEW_ALL_TIMESLOTS";
export const SAVE_TIMESLOTS = "SAVE_TIMESLOTS";
export const PLACE_ORDER = "PLACE_ORDER";
export const CALCULATE_SURGE = "CALCULATE_SURGE";
export const ADD_SUB = "ADD_SUB";
export const LOAD_MAIN_ITEM = "LOAD_MAIN_ITEM";
export const ADD_MAIN_ITEM = "ADD_MAIN_ITEM";
export const REMOVE_MAIN_ITEM = "REMOVE_MAIN_ITEM";
export const UPDATE_MAIN_ITEM = "UPDATE_MAIN_ITEM";
export const EMPTY_CART = "EMPTY_CART";

