export const LOGIN_OTP_USER = "LOGIN_OTP_USER";
export const SEND_CUSTOM_OTP = "SEND_CUSTOM_OTP";
export const LOGOUT_USER = "LOGOUT_USER";
export const REGISTER_USER_OTP = "REGISTER_USER_OTP";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const USER_UPDATE_USER_INFO = "USER_UPDATE_USER_INFO";
export const USER_WALLET_TRANSACTION = "USER_WALLET_TRANSACTION";
export const USER_VIEW_SUBSCRIPTION = "USER_VIEW_SUBSCRIPTION";
export const USER_VIEW_SINGLE_SUBSCRIPTION = "USER_VIEW_SINGLE_SUBSCRIPTION";
export const GET_PLAN_FEATURES = "GET_PLAN_FEATURES";
export const VIEW_PROFILE_DATA = "VIEW_PROFILE_DATA";
export const RATE_OUR_ITEM ="RATE_OUR_ITEM";
export const REFERAL_DETAILS ="REFERAL_DETAILS";
export const LIST_USER_VOUCHER ="LIST_USER_VOUCHER";



