import {
    ADD_SUBCART,
    ADD_TYPE,
    ADD_TOTAL,
    UPDATE_SUB_CART,
    PLUS_PRODUCT,
    MINUS_PRODUCT,
    ADD_BRANCH,
} from './actionTypes'


const initialState = {
    subProducts: [],
    plan:"", 
    total:"",
    tax:"",
    orderProducts:[],
    branch:"",  
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_SUBCART:
            return {
                ...state,
                subProducts: action.payload
            };
        case ADD_TYPE:
            console.log(action.payload)
            return {
                ...state,
                plan:action.payload
            }; 
        case ADD_TOTAL:
            return {
                ...state,
                total: action.payload.total,tax:action.payload.tax
            };  
        case UPDATE_SUB_CART:
                return {
                    ...state,
                    orderProducts: action.payload                    
                }; 
        case ADD_BRANCH:
                    return {
                        ...state,
                        branch: action.payload                    
                    };                 
        default:
            return state;
    }
}
