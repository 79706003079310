/* eslint-disable import/no-anonymous-default-export */

import { GET_MY_ORDERS, CANCEL_ORDER } from "./actionTypes";

const initialState = {
  orders: [],
  cancel_order: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MY_ORDERS:
      return { ...state, orders: action.payload };
    case CANCEL_ORDER:
      return { ...state, cancel_order: action.payload };
    default:
      return state;
  }
}
